


















import CarsPriceHistoryDocumentItemModel from '@/modules/cars/models/cars-price-history-document-item.model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import Demand from '@/modules/common/components/ui-kit/demand.vue';
import Occupancy from '@/modules/common/components/ui-kit/occupancy.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import Day from '@/modules/common/types/day.type';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';
import CarsPriceHistoryService, { CarsPriceHistoryServiceS } from '@/modules/cars/cars-price-history.service';
import GRAPH_COLORS from '@/modules/common/constants/default-graph-colors.constant';
import CALENDAR_DATA_SOURCE from '@/modules/cars/constants/calendar-data-source.constant';
import RatesGraphTooltip from '@/modules/cars/components/rates-graphs/rates-graph-tooltip.vue';
import CarsAnalysisService, { CarsAnalysisServiceS } from '../../cars-analysis.service';

@Component({
    components: {
        CustomGraph, Occupancy, Demand, LoaderWrapper, RatesGraphTooltip,
    },
})
export default class PriceHistoryGraphCars extends Vue {
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(UserServiceS) protected userService!: UserService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(CarsPriceHistoryServiceS) carsPriceHistoryService!: CarsPriceHistoryService;
    @Inject(CarsAnalysisServiceS) carsAnalysisService!: CarsAnalysisService;

    private daysLabel: Map<number, Record<string, boolean>> = new Map();
    private allLabelsHaveAsterisk: boolean = false;

    @Prop({ default: [], type: Array })
    disabled!: string[];

    @Prop({
        type: Boolean,
        default: false,
    })
    isLastUpdateWithAsterisk!: boolean;

    @Prop({
        type: String,
        default: '',
    })
    selectedCarClass!: string;

    onTooltipClick(day: string) {
        const normalizedDay = this.normalizedDay(day);
        const newDay = Number.isNaN(normalizedDay) ? 0 : normalizedDay;
        const newDayLabel = ['Last,Update', 'Last,Update*'].includes(day) ? '' : day;
        this.$emit('setDay', newDay, newDayLabel, this.allLabelsHaveAsterisk);
    }

    get chartData(): ChartData {
        const datasets: ChartDataSets[] = [];
        const { competitors, isBrandMode, isBrokerMode } = this.carsFiltersService;
        const { brokersCompetitors } = this.carsService;
        const { isBrokerToBrand, isBrokerToBroker } = this.carsService;
        const { currentCompany } = this.userService;

        if (!this.carsPriceHistoryService.get30Days || !currentCompany) {
            return {
                labels: [],
                datasets: [{ data: [] }],
            };
        }

        if (isBrandMode && !competitors) {
            return {
                labels: [],
                datasets: [{ data: [] }],
            };
        }

        if (isBrokerMode && !brokersCompetitors) {
            return {
                labels: [],
                datasets: [{ data: [] }],
            };
        }

        let companies = [...(competitors || [])].filter(competitor => !this.disabled.includes(competitor));

        if (isBrokerToBroker) {
            companies = [...(brokersCompetitors || [])];
        }

        if (!this.disabled.includes(currentCompany)) {
            companies.unshift(currentCompany);
        }

        companies.forEach(nameOfCompany => {
            const data: (number | null)[] = [];
            let company = nameOfCompany;
            this.carsPriceHistoryService.get30Days.forEach(day => {
                const allCars = (this.selectedCarClass
                    ? this.carsAnalysisService.allCarsForSelectedCarClass(day, [this.selectedCarClass], true, undefined, CALENDAR_DATA_SOURCE.HISTORY)
                    : this.carsService.allCars(day, true, undefined, CALENDAR_DATA_SOURCE.HISTORY)) as {
                    [company: string]: CarsPriceHistoryDocumentItemModel
                };
                if (!this.daysLabel.has(day)) {
                    this.daysLabel.set(day, { });
                }

                if (!allCars) {
                    data.push(null);
                    this.daysLabel.set(day, { ...this.daysLabel.get(day), [company]: true });
                    return;
                }

                if (isBrokerToBrand && nameOfCompany === currentCompany) {
                    const search = Object.keys(allCars).find(brand => brand.split(',')[0] === currentCompany);
                    if (search) {
                        company = search;
                    }
                }

                if (isBrokerToBroker) {
                    const search = Object.keys(allCars).find(brand => brand.split(',')[0] === nameOfCompany);
                    if (search) {
                        company = search;
                    }
                }
                const car = allCars[company];
                if (car) {
                    data.push(car.price);
                } else {
                    data.push(null);
                }
                this.daysLabel.set(day, { ...this.daysLabel.get(day), [company]: !!car?.paymentTerms });
            });

            const currentData = this.currentTodayData(nameOfCompany);
            data.push(currentData);
            datasets.push({
                label: String(company),
                data,
                borderColor: this.borderColor(company),
                borderWidth: company.split(',')[0] === currentCompany ? 3 : 2,
                lineTension: 0,
                borderJoinStyle: 'round',
            });
        });
        return {
            labels: this.getLabels(),
            datasets,
        };
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    tension: 0,
                },
                point: {
                    radius: 3,
                    backgroundColor: '#ECF1F5',
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                    ticks: {
                        fontSize: 11,
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                    },
                    offset: true,
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }

    borderColor(companyName: string) {
        const { currentCompany } = this.userService;
        const { isBrokerToBrand, isBrokerToBroker } = this.carsService;

        if (isBrokerToBrand || isBrokerToBroker) {
            const [broker] = companyName.split(',');
            return PROVIDER_COLORS[broker] || GRAPH_COLORS.pop() || '#123456';
        }

        return currentCompany && companyName === currentCompany ? PROVIDER_COLORS[currentCompany] : this.colorByHotel(companyName);
    }

    colorByHotel(companyName: string) {
        return this.carsService.carsGraphColor[companyName];
    }

    getLabels() {
        const labels: any[] = [];
        const daysLabel = [...this.carsPriceHistoryService.get30Days.map(day => {
            const companyMap = this.daysLabel.get(day);
            const dayLabel = day < 10 ? `-0${day}` : `-${day}`;
            if (companyMap) {
                const isLabelWithoutAsterisk = Object.values(companyMap).some(value => value);
                return isLabelWithoutAsterisk ? dayLabel : `${dayLabel}*`;
            }
            return dayLabel;
        })];
        const labelWithoutAsterisk = daysLabel.some((day: string) => !day.includes('*'));
        if (!labelWithoutAsterisk) {
            this.allLabelsHaveAsterisk = true;
            labels.push(...this.carsPriceHistoryService.get30Days.map(day => (day < 10 ? `-0${day}` : `-${day}`)));
        } else {
            this.allLabelsHaveAsterisk = false;
            labels.push(...daysLabel);
        }

        const lastUpdate = this.isLastUpdateWithAsterisk ? 'Update*' : 'Update';
        this.carsPriceHistoryService.isNoteVisible = [lastUpdate, ...labels].some((day: string) => day.includes('*'));

        labels.push(['Last', lastUpdate]);
        return labels;
    }

    currentTodayData(nameOfCompany: string) {
        const date = 0 as Day;
        const allCars = this.carsService.allCars(date, true, undefined, CALENDAR_DATA_SOURCE.HISTORY) as {
            [company: string]: CarsPriceHistoryDocumentItemModel
        };
        let company = nameOfCompany;

        const { isBroker } = this.carsService;

        if (!allCars) {
            return null;
        }
        if (isBroker) {
            const search = Object.keys(allCars).find(brand => brand.split(',')[0] === nameOfCompany);
            if (search) {
                company = search;
            }
        }

        const car: CarsPriceHistoryDocumentItemModel = allCars[company];
        if (car) {
            return car.price;
        }
        return null;
    }

    normalizedDay(day: string) {
        return Number(day.replace('*', ''));
    }
}
